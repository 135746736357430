import { Grid } from '@sumup/circuit-ui/legacy';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import {
  isDigitalProduct,
  type DigitalProductWithoutPrices,
  type ProductWithPrices,
} from 'productSelection/services/product';
import type { ProductCardContent } from 'productSelection/types/content';
import type {
  DigitalProduct,
  LineItemType,
  Product,
} from 'productSelection/types/products';
import { useAddProduct } from 'shared/hooks/orders/useAddProduct';
import type {
  SkuCodePurchaseLimit,
  SkuPriceData,
} from 'shared/infra/storefront/markets/prices';
import { ENTITIES } from 'shared/services/walker/constants';
import { getTaggingData } from 'shared/services/walker/tagging';
import { useTypedSelector } from 'shared/store';
import type { OrderLineItem } from 'shared/store/order/types';
import type { SKUCode } from 'shared/types/ids';
import { ProductCard } from 'src/productSelection/components/ProductCard';

import { CardWrapper, GridWrapper } from './ProductGallery.styles';
import { getOrderedProducts } from './ProductGalleryService';

interface Props {
  products?: Product[];
  digitalProducts?: DigitalProduct[];
  productCardContent?: ProductCardContent;
  prices: Record<SKUCode, SkuPriceData>;
  addToCartText?: string;
  isDiscountPriceEnabled?: boolean;
  taxMessage: string;
  purchaseLimitsBySkuCodes: SkuCodePurchaseLimit[];
  isTTPOIFlagEnabled: boolean;
}

export const ProductGallery: FC<Props> = ({
  products,
  prices,
  productCardContent,
  taxMessage,
  digitalProducts,
  addToCartText,
  isDiscountPriceEnabled = true,
  purchaseLimitsBySkuCodes,
  isTTPOIFlagEnabled,
}) => {
  const { addProduct } = useAddProduct();
  const { locale, query } = useRouter();

  const { operationStatus, products: orderProducts } = useTypedSelector(
    (state) => state.order,
  );

  const isAddingProduct = (code: SKUCode): boolean =>
    code === operationStatus.code && operationStatus.loading;

  const addProductToCart = async (
    sku: SKUCode,
    lineItemType: LineItemType,
    product: ProductWithPrices,
    quantity = 1,
  ): Promise<void> => {
    const price = prices[sku];
    const applicableCouponCode = price?.appliedCouponCode || '';
    const selectedProduct = orderProducts.find((item) => item.code === sku);
    const finalQuantity = (selectedProduct?.quantity || 0) + quantity;

    await addProduct({
      id: selectedProduct?.id,
      trackingId: product.trackingId,
      formattedUnitAmount: product.formattedUnitAmount,
      code: sku,
      lineItemType,
      quantity: finalQuantity,
      couponCode: applicableCouponCode,
      locale,
      allowOnlyOne: false,
      localizedName: products?.find((p) => p.code === product.code)?.name,
    });

    return undefined;
  };

  const handleProductSelection =
    (product: ProductWithPrices) =>
    async (
      sku: SKUCode,
      lineItemType: LineItemType,
      quantity = 1,
    ): Promise<void> => {
      await addProductToCart(sku, lineItemType, product, quantity);
    };

  const productList = getOrderedProducts(
    products,
    digitalProducts,
    prices,
    locale,
    query,
    taxMessage,
    isTTPOIFlagEnabled,
  );

  const catalogGalleryLength = productList.length;
  const productsInARowForDesktop =
    catalogGalleryLength < 5 ? catalogGalleryLength : 3;

  const hasProductInstallmentsOnPage = products.some(
    (p) => p.numberOfInstallments > 1,
  );

  return (
    <Grid>
      <GridWrapper cols={productsInARowForDesktop}>
        {productList.map(
          (product: ProductWithPrices | DigitalProductWithoutPrices) => {
            if (isDigitalProduct(product)) {
              const digitalProductContent = {
                ...productCardContent,
                discountBadgeText: product.discountBadgeText,
              };

              return (
                <CardWrapper key={product.id}>
                  <ProductCard
                    {...product}
                    isDigitalProduct
                    amountFloat={0}
                    content={digitalProductContent}
                    productsCount={catalogGalleryLength}
                    addToCartText={addToCartText}
                    stockQuantity={null}
                  />
                </CardWrapper>
              );
            }
            const { purchaseLimit } =
              purchaseLimitsBySkuCodes.find(
                (limit) => limit.code === product.code,
              ) || {};

            const orderProduct = orderProducts.find(
              (p: OrderLineItem) => p.code === product.code,
            );

            const isSelected = orderProduct?.quantity >= purchaseLimit;

            return (
              <CardWrapper
                key={product.id}
                {...getTaggingData({
                  entity: ENTITIES.PRODUCT,
                })}
              >
                <ProductCard
                  {...product}
                  orderProduct={orderProduct}
                  purchaseLimit={purchaseLimit}
                  formattedTotalAmount={product.formattedAmount}
                  productsCount={catalogGalleryLength}
                  taxMessage={product.formattedTaxMessage}
                  formattedUnitAmount={product.formattedCompareAmount}
                  content={productCardContent}
                  isSelected={isSelected}
                  isHighlighted={!!orderProduct}
                  isLoading={isAddingProduct(product.code)}
                  onSelect={handleProductSelection(
                    product as ProductWithPrices,
                  )}
                  isInstallmentsLayout={hasProductInstallmentsOnPage}
                  shortBenefits={product.shortBenefits}
                  addToCartText={addToCartText}
                  isDiscountPriceEnabled={isDiscountPriceEnabled}
                />
              </CardWrapper>
            );
          },
        )}
      </GridWrapper>
    </Grid>
  );
};
